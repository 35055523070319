<template>
    <div v-if="orderProductForOrderRegistration(0)" id="requiredDays" class="position-fixed rounded">
        <div class="d-flex align-items-center">
            <img src="@/plugins/app/order/components/img/info.svg" class="mr-2" />
            <div>
                <span>Počet dní požadovaných pre pokračovanie v objednávke: </span>
                <template v-if="orderRegistrations.length == 1">
                    <span class="font-weight-bold">{{orderProductForOrderRegistration(0).minimum_selected_days - totalDays}}</span>
                </template>
                <template v-else>
                    <div v-for="(or, i) in orderRegistrations" :key="i">
                    <template v-if="orderProductForOrderRegistration(i)">
                        <template v-if="orderProductForOrderRegistration(i).minimum_selected_days - or.order_registration_dates.length > 0">
                        Študent {{i + 1}}: 
                        <span class="font-weight-bold">{{orderProductForOrderRegistration(i).minimum_selected_days - or.order_registration_dates.length}}</span>
                        </template>
                    </template>
                    <template v-else>
                        Študent {{i + 1}}: Je nutné vybrať produkt a produktovú periódu.
                    </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('order', ['orderRegistrations']),
    ...mapGetters('order', { orderProductForOrderRegistration: 'productForOrderRegistration' }),

    totalDays(){
      return this.orderRegistrations.reduce((totalDays, student) => {
        return totalDays + (student.order_registration_dates ? student.order_registration_dates.length : 0)
      }, 0)
    }
  },
}
</script>


<style lang="sass" scoped>
#requiredDays
    background-color: #F3CECE
    color: #A5302B
    padding: 8px 50px
    bottom: 100px
    z-index: 9999
    left: 50%
    transform: translate(-50%, 0)
    visibility: hidden
    opacity: 0
    font-size: 18px
    transition: visibility 0.5s, opacity 0.5s

    img
        height: 15px
        width: 15px

@media screen and (max-width: 1200px)
    #requiredDays
        padding: 8px 10px

</style>